<template>
	<div class="aCustomSelect field" key="fieldname">

		<!-- Label -->
		<label :for="fieldname">{{metaData.description}}&nbsp;<span v-if="isRequired" class="req">*</span></label>

		<!-- Dropdown -->
		<div class="dropdown" :class="{'open': isListOpen}">

			<input
			type="text"
			autocomplete = "off"
			:id="fieldname"
			:placeholder="placeholder"
			v-model="textInField"
			@input="userInputChanged"
			@keyup.prevent.down="down"
			@keyup.prevent.up="up"
			@keyup.prevent.enter="enter"
			:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
			>

			<!-- Toggler -->
			<div class="toggle" @click="displaySuggestions(!isListOpen)">
				<span class="arrow-up" v-if="isListOpen">▲</span>
				<span class="arrow-down" v-if="!isListOpen">▼</span>
			</div>

			<!-- Suggestions list -->
			<ul class="suggestion-list">
				<li
				v-for="(item, index) in optionsList"
				tabindex="1"
				:key="index"
				:class="{'active': currentActiveSuggestion === index}"
				@click="selectSuggestion(item, index)"
				>
				{{item.description}}
			</li>
		</ul>

	</div>
	<!-- END Dropdown -->


	<!-- Displaying an error message -->
	<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>

	<!-- Helper for this input -->
	<div class="helper">{{helper}}</div>

</div>
</template>































<script>

	import FormFields from "../mixins/FormFields";
	import config from "../config";

	export default {
		name:"customSelect",
		mixins: [FormFields],

		props : {
			dataFrom : {
				type: String,
				required: true
			},
			modelValue: {
				required: false,
				// This IS received in case of reparticipation…
			},
			isReparticipation: {
				type: Boolean,
				required: true,
			}
		},

		data() {
			return {
				metaData: [{
					leadmanagementmetadata: {
						description: "",
						leadmanagementmetadatavalue: []
					}
				}],
				isListOpen: false,
				currentActiveSuggestion: 0,
				errorMessage: '',
				isInError: false,
				searchText: '',
				config: config,
			}
		},

		computed: {

			optionsList(){
				let list = this.metaData.leadmanagementmetadatavalue;
				/* Ajouter un peu de nettoyage (trim) des descriptions */
				if(typeof list !== "undefined") {
					for (let item of list){
						item.description = item.description.trim();
					}
				} else {
					return [];
				}
				return list;
			},



			textInField: {
				get(){
					let receivedId = this.modelValue;
					// console.log(this.optionsList);

					let result = this.optionsList.find(obj => {
						return obj.id == receivedId;
					});
					if(result){
						console.log(result.description);
						// this.$emit
						this.sendCheckSignal(true); // if required
						this.giveSuccess();
						return result.description;
					}else{
						return "";
					}
					
				},

				set(str){
					this.searchText = str;
				}
			},



		},

		watch: {
			/* Watch locale, not lang, as nl_BE is sometimes different from nl_NL (and fr_BE could be different from fr_LU) */
			'$store.getters.getLocale': function( /* newLocale, oldLocale */ ){
				// console.log(oldLocale + " --> " + newLocale);
				/* Must trigger a refresh of data from here */
			this.getMetaDataFromAPI(this.dataFrom);
		},
	},

	methods: {
		up() {
			if(this.isListOpen && this.currentActiveSuggestion > 0){
				this.upOneItem();
			}else{
				this.displaySuggestions(false);
				this.currentActiveSuggestion = 0;
			}
		},
		down(){
			if(this.isListOpen && this.currentActiveSuggestion < this.optionsList.length -1){
				this.downOneItem();
			}else if(!this.isListOpen){
				this.displaySuggestions(true);
				this.currentActiveSuggestion = 0;
			}
		},
		enter(){
			if( !this.checkIfImValid() ){
				this.setFieldInError("ERROR MESSAGE TO BE CHANGED");
			}else{
				this.setSelection(this.optionsList[this.currentActiveSuggestion]);
			}
			this.checkIfImValid();
			this.displaySuggestions(false);
		},

		isThisFieldValid(){
				/* What is a valid answer ? An answer that is exactly in the list */
			let listSuggestions = [];
			for(let item of this.optionsList){
				listSuggestions.push(item.description.trim().toLowerCase());
			}
			return listSuggestions.includes(this.searchText.trim().toLowerCase());
		},

		checkIfImValid(){
			if(this.isThisFieldValid()){
				console.log("This field " + this.fieldname + " IS valid.");
				this.giveSuccess();
			}else{
				console.log("This field " + this.fieldname + " IS NOT valid.");
				this.giveError();
			}
			this.sendCheckSignal(this.isThisFieldValid());
			return this.isThisFieldValid();
		},

		setFieldInError(msg="Entry is invalid"){
			this.errorMessage = msg;
		},

		setSelection(data){
			try{
				this.$emit('update:modelValue', data.id);
				this.textInField = data.description;
				this.sendCheckSignal(true);
				this.checkIfImValid();
			}catch(e){
				console.error(e);
			}
		},

			/* What to do when a key is pressed */
		upOneItem(){
				// console.log("Up one item");
			if(this.currentActiveSuggestion > 0){
				this.currentActiveSuggestion -= 1;
				this.searchText = this.optionsList[this.currentActiveSuggestion].description;
			}
		},
		downOneItem(){
				// console.log("down one item");
			if(this.currentActiveSuggestion < this.optionsList.length -1){
				this.currentActiveSuggestion += 1;
				this.searchText = this.optionsList[this.currentActiveSuggestion].description;
			}
		},

			/* Other stuff */
		selectSuggestion (suggestion, index) {
			this.closeList();
			this.searchText = suggestion.description;
			this.$emit('update:modelValue', suggestion.id);
			this.currentActiveSuggestion = index;
			this.sendCheckSignal(true);
			this.checkIfImValid();
		},

		sendCheckSignal(bool=false){
			this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
		},

		userInputChanged() {
			if (!this.isListOpen) {
				this.openList();
			}
		},

		openList(){
				/* to "refresh" the content of the list following current locale */
			this.removeText();
			this.getMetaDataFromAPI();
			this.isListOpen = true;
		},
		closeList(){
			this.isListOpen = false;
		},

		removeText(){
			this.currentActiveSuggestion = '';
			this.searchText = "";
			this.textInField = "";
		},

		displaySuggestions(bool){
				/* bool === true ? => open list */
				/* bool === false ? => close list */
			if(bool){
				this.openList();
			}else{
				this.closeList();
			}
				// console.log("Displaying options");
		},

		getMetaDataFromAPI(){
			this.setLoading();
				this.$store.commit('SET_LOCALE',this.$i18n.locale.substring(0,2)); /* To be sure the lang of the request is correct */
			this.axios.get(`/dropdown/${config.idlead}/metadata/${this.dataFrom}`)
			.then(res => {
					// console.log("API Call: success");
					/* Filtering data to keep the one we need for this instance of the component: */
				this.metaData = res.data;
			})
			.catch(error => {
				console.error(error);
				if(error.response.status === 503){
					this.setNotLoading();
					this.$emit('maintenanceDetected');
				}else{
					this.isInError = true;
					this.errorMessage = error;
				}
			})
			.then(() => {
					// console.log("Appel API terminé");
			});
			this.setNotLoading();
		}
	},

	created() {
		this.getMetaDataFromAPI();
	},
	mounted(){
	}
}

</script>


































<style lang="scss" scoped>

	.dropdown {
		display: inline-block;
		position: relative;
	}

	.suggestion-list {
		background-color: rgba(255, 255, 255, 0.95);
		border: 1px solid #ddd;
		list-style: none;
		display: block;
		margin: 0;
		padding: 0.5em;
		width: 100%;
		overflow: hidden;
		overflow-y:auto;
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 2;
		max-height:15em;
	}

	.dropdown .suggestion-list {
		display: none;
	}

	.dropdown.open .suggestion-list {
		display: block;
	}

	.toggle {
		position: absolute;
		top:50%;
		transform:translateY(-60%);
		right:1.25em;
		cursor: pointer;
	}

	.toggle .arrow-up {
		display: none;
	}

	.open .toggle .arrow-up {
		display: inline-block;
	}

	.open .toggle .arrow-down {
		display: none;
	}

	.suggestion-list li {
		cursor: pointer;
		padding:0.25em;
	}

	.suggestion-list li:hover {
		color: #fff;
		background-color: #ccc;
	}

	li.active {
		background: #fc0;
	}

</style>

