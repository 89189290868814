<template>
  <div class="aCustomInput field">
    <label  :for="fieldname">{{label}} <span v-if="isRequired" class="req">*</span></label>
    <input
    :id="fieldname"
    :type="inputType"
    v-model="modelValueComputed"
    :placeholder="placeholder"
    :disabled="disabled"
    :class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
    >
    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper">{{helper}}</div>
  </div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
  import { debounce } from "lodash";
  import FormFields from "../mixins/FormFields";
  import dayjs from "dayjs";
  import config from "../config";
  var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
  dayjs.extend(isSameOrBefore)
  export default {
    name: "customInput",

    emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

    mixins: [FormFields],

    props: {
      inputType:{
        type: String,
        validator : function(value){
          return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
        }
      },
      label : {
        type: String,
        required : true
      },
      disabled : {
        type: Boolean,
        required: false,
      default: false
      },
      acceptedValues: {
        type: Array, 
        required: false
      },
      valueHasChanged: {
        type: String,
        required: false
      },
    },

    data(){
      return {
        errorMessage : "",
        config: config,
      }
    },

    computed: {
      modelValueComputed: {
        get() {
          return this.modelValue;
        },
        set(newVal) {
          this.validateMyselfDebounced();
          this.$emit('update:modelValue', newVal);
        }
      }
    },

    methods : {


      validateMyself() {
        this.setLoading();
        this.removeSuccessOrError();

        console.group("FreeInput Validation " + this.fieldname);
        console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);

      /* CUSTOM VALIDATION */
      /* Acceptables values are given by a prop : acceptedValues */
      /* If none are given, a non-empty text is enough to validate the field */
        
        if (this.acceptedValues.length > 0){
          this.thereIsAListOfAcceptedValues();
        }else{
          this.thereIsNoListOfAcceptedValues();
        }
        console.groupEnd();
        this.setNotLoading();
      },

      thereIsAListOfAcceptedValues(){
        if(this.acceptedValues.includes(this.modelValueComputed)){
          console.log("Value accepted");
          this.giveSuccess();
          this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
        }else{
          console.log("Value rejected");
          this.giveError();
          this.errorMessage = this.$t('inputsTexts.freeInput.error_wrongValue');
          this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
        }
      },

      thereIsNoListOfAcceptedValues(){
        if(this.modelValueComputed.length > 0){
          this.giveSuccess();
          this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
        }else{
          this.giveError();
          this.errorMessage = this.$t('inputsTexts.freeInput.error_empty');
          this.$emit('runCheck', {fieldname: this.fieldname, valid: false});
        }
      },


    },

    created() {
      this.validateMyselfDebounced = debounce(this.validateMyself, 500);
    // run validation immediately when the value is not null or empty
      if(this.modelValueComputed) {
        this.validateMyselfDebounced();
      }
    }
  }

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
