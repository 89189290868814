<template>

	<div id="macaron">
		<div class="macacontent">
			<div class="big"><slot name="big"></slot></div>
			<div class="small"><slot name="small"></slot></div>
		</div>		
	</div>

</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:""
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
